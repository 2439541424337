import React, { useEffect, useState } from 'react';
import {
  Stack,
  Grid,
  GridItem,
  Button,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';
import * as CryptoJS from 'crypto-js';
import { Form, Formik } from 'formik';
import { minimumDate } from '@helpers';

const validateForm = (values) => {
  const requiredFields = ['physician_name', 'clinic_name', 'clinic_zip', 'next_visit'];
  const errors = {};

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    } else if (field === 'clinic_zip' && !/^\d{5}$/i.test(values.clinic_zip)) {
      errors.clinic_zip = 'Invalid ZIP Code';
    }
  });
  return errors;
};

const hashAndSendToGTM = (values) => {
  const hashedDoctorName = CryptoJS.SHA256(values.physician_name).toString(CryptoJS.enc.Hex);
  const hashedClinicName = CryptoJS.SHA256(values.clinic_name).toString(CryptoJS.enc.Hex);
  const hashedClinicZip = CryptoJS.SHA256(values.clinic_zip).toString(CryptoJS.enc.Hex);

  window.dataLayer.push({
    event: 'lead_created',
    hashed_doctor_name: hashedDoctorName,
    hashed_clinic_name: hashedClinicName,
    hashed_clinic_zip: hashedClinicZip,
  });
};

const DoctorInfo = ({ data, setData }) => {
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if (isComplete) {
      const timeoutId = setTimeout(() => {
        if (window.self === window.top) {
          // If not in a frame, redirect to the thank-you page
          window.location.href = 'https://www.babypeek.com/thank-you';
        } else {
          // If in a frame, post a message to the parent window
          window.parent.postMessage('navigateToThankYou', '*');
        }
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [isComplete]);

  return (
    <Formik
      initialValues={{
        physician_name: data.physician_name || '',
        clinic_name: data.clinic_name || '',
        clinic_zip: data.clinic_zip || '',
        next_visit: data.next_visit || '',
      }}
      validate={(values) => validateForm(values)}
      onSubmit={(values, { setSubmitting }) => {
        setData({ ...values, completed: true }); // Add completed flag to data object
        setSubmitting(false);
        hashAndSendToGTM(values);
        setIsComplete(true);
      }}
    >
      {({ errors, isSubmitting, handleChange, handleBlur, handleSubmit, values, touched }) => (
        <Stack
          as={Form}
          onSubmit={handleSubmit}
          gap={{ base: 20, sm: 40 }}
        >
          <Stack gap={{ base: 10, sm: 20 }}>
            <Text
              as='h1'
              textStyle='h1'
            >
              Share your doctor’s information
            </Text>
            <Text>Our team will reach out to unlock UNITY and BabyPeek for you.</Text>
          </Stack>
          <Grid
            gridTemplateColumns={{ base: '1fr', sm: 'repeat(2, 1fr)' }}
            rowGap={{ base: 5, sm: 10 }}
            columnGap={20}
          >
            <GridItem>
              <FormControl
                isRequired
                isInvalid={errors.physician_name && touched.physician_name}
              >
                <FormLabel requiredIndicator={null}>Physician Name</FormLabel>
                <Input
                  type='text'
                  name='physician_name'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.physician_name}
                />
                <FormErrorMessage>{errors.physician_name}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl
                isRequired
                isInvalid={errors.clinic_name && touched.clinic_name}
              >
                <FormLabel requiredIndicator={null}>Clinic Name</FormLabel>
                <Input
                  type='text'
                  name='clinic_name'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.clinic_name}
                />
                <FormErrorMessage>{errors.clinic_name}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl
                isRequired
                isInvalid={errors.clinic_zip && touched.clinic_zip}
              >
                <FormLabel requiredIndicator={null}>Clinic ZIP Code</FormLabel>
                <Input
                  type='string'
                  name='clinic_zip'
                  maxLength='5'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.clinic_zip}
                />
                <FormErrorMessage>{errors.clinic_zip}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel requiredIndicator={null}>Next Visit Date</FormLabel>
                <Input
                  type='date'
                  name='next_visit'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.next_visit}
                  min={minimumDate}
                />
                <FormErrorMessage>{errors.next_visit}</FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>
          <Button
            type='submit'
            size={{ base: 'sm', sm: 'md' }}
            alignSelf='flex-start'
            isLoading={isSubmitting}
          >
            Request Access
          </Button>
        </Stack>
      )}
    </Formik>
  );
};

export default DoctorInfo;
