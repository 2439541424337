import React, { useEffect, useCallback } from 'react';
import {
  Box,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  Stepper,
  useSteps,
} from '@chakra-ui/react';

function StepControls({ steps, step, goToStep }) {
  const { activeStep, setActiveStep } = useSteps({
    index: step - 1,
    count: steps.length,
  });

  const setCurrentStep = useCallback(
    (index) => {
      goToStep(index + 1);
      setActiveStep(index);
    },
    [goToStep, setActiveStep],
  );

  useEffect(() => {
    setCurrentStep(step - 1);
  }, [setCurrentStep, step]);

  return (
    <Box
      display='flex'
      flexDirection='column'
    >
      <Stepper
        index={activeStep}
        gap={{ base: '0.5rem', sm: '1rem' }}
        size={{ base: 'sm', sm: 'md' }}
      >
        {steps.map((step, index) => {
          const isCompleted = index < activeStep;

          return (
            <Step
              key={index}
              onClick={() => (isCompleted ? setCurrentStep(index) : null)}
              cursor={isCompleted ? 'pointer' : 'not-allowed'}
              gap={{ base: 0, sm: '0.5rem' }}
            >
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <StepSeparator flex='1 auto' />
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}

export default StepControls;
