import React from 'react';
import { Flex } from '@chakra-ui/react';

function Layout({ children }) {
  return (
    <Flex
      flexDirection='row'
      alignItems='stretch'
      minHeight='100vh'
      overflow='hidden'
    >
      {children}
    </Flex>
  );
}

export default Layout;
