import React from 'react';
import PhoneInput from 'react-phone-number-input/input';
import { Field } from 'formik';
import { Input } from '@chakra-ui/react';

const PhoneInputField = ({ name }) => (
  <Field name={name}>
    {({ field, form }) => (
      <Input
        as={PhoneInput}
        {...field}
        country='US'
        maxLength='14'
        onChange={(value) => {
          form.setFieldValue(field.name, value);
        }}
        onBlur={() => {
          form.setFieldTouched(field.name, true);
        }}
      />
    )}
  </Field>
);

export default PhoneInputField;
