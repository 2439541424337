import * as CryptoJS from 'crypto-js';

// This function will send a request to a Netlify serverless function. The serverless function makes a request to Customer.io via the REST API to identify a customer
export const identifyCustomer = (customerId, customerDataObject) =>
  new Promise((resolve, reject) => {
    fetch('/.netlify/functions/identifyCustomer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ customerId, customerDataObject }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(`Error identifying customer: ${customerDataObject.email}`);
        reject(error);
      });
  });

export const getCustomerAttributes = (sessionId) =>
  new Promise((resolve, reject) => {
    fetch('/.netlify/functions/getCustomerAttributes', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ sessionId }),
    })
      .then((response) => {
        if (!response.ok) {
          return false;
        }
        return response.json();
      })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const generateSessionToken = () => {
  const randomBytes = CryptoJS.lib.WordArray.random(8);
  const sessionToken = randomBytes.toString(CryptoJS.enc.Hex);

  return sessionToken;
};
