import React, { useEffect, useState } from 'react';
import {
  Stack,
  Grid,
  GridItem,
  Button,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { minimumDate } from '@helpers';
import * as CryptoJS from 'crypto-js';

const validateForm = (values) => {
  const requiredFields = ['first_name', 'last_name', 'email', 'due_date'];
  const errors = {};

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  return errors;
};

const hashAndSendToGTM = (values) => {
  const { first_name: firstName, last_name: lastName, email } = values;
  const hashedEmail = CryptoJS.SHA256(email).toString(CryptoJS.enc.Hex);

  window.dataLayer.push({
    event: 'personal_info_submitted',
    first_name: firstName,
    last_name: lastName,
    email,
    hashed_email: hashedEmail,
  });
};

const UserInfo = ({ data, setData, goToNextStep }) => {
  const [isComplete] = useState(false);

  useEffect(() => {
    if (isComplete) {
      const timeoutId = setTimeout(() => {
        if (window.self === window.top) {
          // If not in a frame, redirect to the thank-you page
          window.location.href = 'https://www.babypeek.com/thank-you';
        } else {
          // If in a frame, post a message to the parent window
          window.parent.postMessage('navigateToThankYou', '*');
        }
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [isComplete]);

  return (
    <Formik
      initialValues={{
        first_name: data.first_name || '',
        last_name: data.last_name || '',
        due_date: data.due_date || '',
        email: data.email || '',
      }}
      validate={(values) => validateForm(values)}
      onSubmit={(values, { setSubmitting }) => {
        setData({ ...values });
        setSubmitting(false);
        hashAndSendToGTM(values);
        goToNextStep();
      }}
    >
      {({ errors, isSubmitting, handleChange, handleBlur, handleSubmit, values, touched }) => (
        <Stack
          as={Form}
          onSubmit={handleSubmit}
          gap={{ base: 20, sm: 40 }}
        >
          <Stack gap={{ base: 10, sm: 20 }}>
            <Text
              as='h1'
              textStyle='h1'
            >
              Tell us about yourself and your pregnancy!
            </Text>
            <Text>
              We’re so excited to help you discover your baby’s traits before birth with BabyPeek.
            </Text>
          </Stack>

          <Grid
            gridTemplateColumns={{ base: '1fr', sm: 'repeat(2, 1fr)' }}
            rowGap={{ base: 5, sm: 10 }}
            columnGap={20}
          >
            <GridItem>
              <FormControl
                isRequired
                isInvalid={errors.first_name && touched.first_name}
              >
                <FormLabel requiredIndicator={null}>First Name</FormLabel>
                <Input
                  type='text'
                  name='first_name'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.first_name}
                />
                <FormErrorMessage>{errors.first_name}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl
                isRequired
                isInvalid={errors.last_name && touched.last_name}
              >
                <FormLabel requiredIndicator={null}>Last Name</FormLabel>
                <Input
                  type='text'
                  name='last_name'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.last_name}
                />
                <FormErrorMessage>{errors.last_name}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl
                isRequired
                isInvalid={errors.email && touched.email}
              >
                <FormLabel requiredIndicator={null}>Email Address</FormLabel>
                <Input
                  type='email'
                  name='email'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel requiredIndicator={null}>Due Date</FormLabel>
                <Input
                  type='date'
                  name='due_date'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.due_date}
                  min={minimumDate}
                />
                <FormErrorMessage>{errors.due_date}</FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>
          <Button
            type='submit'
            size={{ base: 'sm', sm: 'md' }}
            alignSelf='flex-start'
            isLoading={isSubmitting}
          >
            Continue
          </Button>
        </Stack>
      )}
    </Formik>
  );
};

export default UserInfo;
